// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import FeatureFlagModal from 'components/FeatureFlagModal/FeatureFlagModal'
import { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { BookOpen, Info, MessageCircle, Shuffle, Twitter } from 'react-feather'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { isDevelopmentEnv, isStagingEnv } from 'utils/env'

import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useModalIsOpen, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { ExternalLink } from '../../theme'

export enum FlyoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.deprecated_text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  height: 40px;
  background-color: ${({ theme }) => theme.deprecated_bg0};
  border: 1px solid ${({ theme }) => theme.deprecated_bg0};
  padding: 0.15rem 0.5rem;
  border-radius: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `}
  &:hover {
    cursor: pointer;
  }
  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span<{ flyoutAlignment?: FlyoutAlignment }>`
  min-width: 196px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.deprecated_bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.deprecated_bg0};
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 3rem;
  z-index: 100;

  ${({ flyoutAlignment = FlyoutAlignment.RIGHT }) =>
    flyoutAlignment === FlyoutAlignment.RIGHT
      ? css`
          right: 0rem;
        `
      : css`
          left: 0rem;
        `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: unset;
    right: 0;
    left: unset;
  `};
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.deprecated_text2};
  :hover {
    color: ${({ theme }) => theme.deprecated_text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const InternalMenuItem = styled(Link)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.deprecated_text2};
  :hover {
    color: ${({ theme }) => theme.deprecated_text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

// FF: Remove language menu
// const InternalLinkMenuItem = styled(InternalMenuItem)`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 0.5rem 0.5rem;
//   justify-content: space-between;
//   text-decoration: none;
//   :hover {
//     color: ${({ theme }) => theme.deprecated_text1};
//     cursor: pointer;
//     text-decoration: none;
//   }
// `

const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.deprecated_text2};
  :hover {
    color: ${({ theme }) => theme.deprecated_text1};
    cursor: pointer;
    text-decoration: none;
  }
`

// FF: Remove language menu
// function LanguageMenuItem({ locale, active, key }: { locale: SupportedLocale; active: boolean; key: string }) {
//   const { to, onClick } = useLocationLinkProps(locale)
//
//   if (!to) return null
//
//   return (
//     <InternalLinkMenuItem onClick={onClick} key={key} to={to}>
//       <div>{LOCALE_LABEL[locale]}</div>
//       {active && <Check opacity={0.6} size={16} />}
//     </InternalLinkMenuItem>
//   )
// }

// FF: Remove language menu
// function LanguageMenu({ close }: { close: () => void }) {
//   const activeLocale = useActiveLocale()

//   return (
//     <MenuFlyout>
//       <ToggleMenuItem onClick={close}>
//         <ChevronLeft size={16} />
//       </ToggleMenuItem>
//       {SUPPORTED_LOCALES.map((locale) => (
//         <LanguageMenuItem locale={locale} active={activeLocale === locale} key={locale} />
//       ))}
//     </MenuFlyout>
//   )
// }

export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const open = useModalIsOpen(ApplicationModal.MENU)
  const toggleMenu = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggleMenu : undefined)
  // FF: Remove legal and privacy
  // const togglePrivacyPolicy = useToggleModal(ApplicationModal.PRIVACY_POLICY)
  const openFeatureFlagsModal = useToggleModal(ApplicationModal.FEATURE_FLAGS)

  // FF: Remove dark mode
  // const [darkMode, toggleDarkMode] = useDarkModeManager()

  const [menu, setMenu] = useState<'main' | 'lang'>('main')

  useEffect(() => {
    setMenu('main')
  }, [open])

  return (
    <>
      {/* // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
      <StyledMenu ref={node as any}>
        <StyledMenuButton onClick={toggleMenu} aria-label={t`Menu`}>
          <StyledMenuIcon />
        </StyledMenuButton>

        {open &&
          (() => {
            switch (menu) {
              // FF: Remove language menu
              // case 'lang':
              //   return <LanguageMenu close={() => setMenu('main')} />
              case 'main':
              default:
                return (
                  <MenuFlyout>
                    {/* FF: Remove about menu item */}
                    {/* <MenuItem href="https://fireflydex.xyz/">
                      <div>
                        <Trans>About</Trans>
                      </div>
                      <Info opacity={0.6} size={16} />
                    </MenuItem> */}
                    {/* FF: Remove Help Center menu */}
                    {/* <MenuItem href="https://discord.gg/fireflydex">
                      <div>
                        <Trans>Help Center</Trans>
                      </div>
                      <HelpCircle opacity={0.6} size={16} />
                    </MenuItem> */}
                    {/* FF: Remove feature requests */}
                    {/* <MenuItem href="https://uniswap.canny.io/feature-requests">
                      <div>
                        <Trans>Request Features</Trans>
                      </div>
                      <Coffee opacity={0.6} size={16} />
                    </MenuItem> */}
                    <MenuItem href="https://pacific-bridge.manta.network/">
                      <div>
                        <Trans>Bridge</Trans>
                      </div>
                      <Shuffle opacity={0.6} size={16} />
                    </MenuItem>
                    <MenuItem href="https://twitter.com/FireFlydexZK">
                      <div>
                        <Trans>Twitter</Trans>
                      </div>
                      <Twitter opacity={0.6} size={16} />
                    </MenuItem>
                    <MenuItem href="https://discord.gg/fireflydex">
                      <div>
                        <Trans>Discord</Trans>
                      </div>
                      <MessageCircle opacity={0.6} size={16} />
                    </MenuItem>
                    {/* FF: Remove language menu */}
                    {/* <ToggleMenuItem onClick={() => setMenu('lang')}>
                      <div>
                        <Trans>Language</Trans>
                      </div>
                      <Globe opacity={0.6} size={16} />
                    </ToggleMenuItem> */}
                    {/* FF: Remove dark mode */}
                    {/* <ToggleMenuItem onClick={() => toggleDarkMode()}>
                      <div>{darkMode ? <Trans>Light Theme</Trans> : <Trans>Dark Theme</Trans>}</div>
                      {darkMode ? <Moon opacity={0.6} size={16} /> : <Sun opacity={0.6} size={16} />}
                    </ToggleMenuItem> */}
                    <MenuItem href="https://docs.fireflydex.xyz/">
                      <div>
                        <Trans>Docs</Trans>
                      </div>
                      <Info opacity={0.6} size={16} />
                    </MenuItem>
                    {/* FF: Add Medium link */}
                    <MenuItem href="https://medium.com/@fireflydex">
                      <div>
                        <Trans>Medium</Trans>
                      </div>
                      <BookOpen opacity={0.6} size={16} />
                    </MenuItem>
                    {/* FF: Remove legal and privacy */}
                    {/* <ToggleMenuItem onClick={() => togglePrivacyPolicy()}>
                      <div>
                        <Trans>Legal & Privacy</Trans>
                      </div>
                      <FileText opacity={0.6} size={16} />
                    </ToggleMenuItem> */}
                    {(isDevelopmentEnv() || isStagingEnv()) && (
                      <ToggleMenuItem onClick={openFeatureFlagsModal}>Feature Flags</ToggleMenuItem>
                    )}
                  </MenuFlyout>
                )
            }
          })()}
      </StyledMenu>
      {/* FF: Remove legal and privacy */}
      {/* <PrivacyPolicyModal /> */}
      <FeatureFlagModal />
    </>
  )
}

interface NewMenuProps {
  flyoutAlignment?: FlyoutAlignment
  ToggleUI?: FunctionComponent<PropsWithChildren<unknown>>
  menuItems: {
    content: any
    link: string
    external: boolean
  }[]
}

const NewMenuFlyout = styled(MenuFlyout)`
  top: 3rem !important;
`
const NewMenuItem = styled(InternalMenuItem)`
  width: max-content;
  text-decoration: none;
`

const ExternalMenuItem = styled(MenuItem)`
  width: max-content;
  text-decoration: none;
`

export const NewMenu = ({ flyoutAlignment = FlyoutAlignment.RIGHT, ToggleUI, menuItems, ...rest }: NewMenuProps) => {
  const node = useRef<HTMLDivElement>()
  const open = useModalIsOpen(ApplicationModal.POOL_OVERVIEW_OPTIONS)
  const toggle = useToggleModal(ApplicationModal.POOL_OVERVIEW_OPTIONS)
  useOnClickOutside(node, open ? toggle : undefined)
  const ToggleElement = ToggleUI || StyledMenuIcon
  return (
    <StyledMenu ref={node as any} {...rest}>
      <ToggleElement onClick={toggle} />
      {open && (
        <NewMenuFlyout flyoutAlignment={flyoutAlignment}>
          {menuItems.map(({ content, link, external }, i) =>
            external ? (
              <ExternalMenuItem href={link} key={i}>
                {content}
              </ExternalMenuItem>
            ) : (
              <NewMenuItem to={link} key={i}>
                {content}
              </NewMenuItem>
            )
          )}
        </NewMenuFlyout>
      )}
    </StyledMenu>
  )
}
