import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { useWeb3React } from '@web3-react/core'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import { ExploreVariant, useExploreFlag } from 'featureFlags/flags/explore'
import useTheme from 'hooks/useTheme'
import { darken } from 'polished'
import { NavLink, useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useNativeCurrencyBalances } from 'state/connection/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'

import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
import { ExternalLink } from '../../theme'
import Menu from '../Menu'
import Row from '../Row'
import Web3Status from '../Web3Status'
import HolidayOrnament from './HolidayOrnament'
import NetworkSelector from './NetworkSelector'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  background-color: ${({ theme }) => theme.deprecated_bg0};
  padding: 1rem;
  z-index: 21;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  1rem;
    grid-template-columns: 36px 1fr;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
  `}
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.deprecated_bg0};
  width: max-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 100%;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    background-color: ${({ theme }) => theme.deprecated_bg0};
    border: 1px solid ${({ theme }) => theme.deprecated_bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
    max-width: 480px;
    width: 90%;
    justify-content: space-between;
    grid-gap: 0;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.deprecated_bg0 : theme.deprecated_bg0)};
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 64px;
  `};
  svg {
    width: 125px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  svg {
    width: 90px;
  }
  `};
`

// can't be customized under react-router-dom v6
// so we have to persist to the default one, i.e., .active
const activeClassName = 'active'

const StyledNavLink = styled(NavLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 8px 18px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    font-weight: 700;
    justify-content: center;
    color: ${({ theme }) => theme.deprecated_text1};
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 8px 12px;
`};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    padding: 8px 10px;
  `};

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
  }
`

const StyledExternalLink = styled(ExternalLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 8px 28px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;

  &.${activeClassName} {
    font-weight: 700;
    justify-content: center;
    color: ${({ theme }) => theme.deprecated_text1};
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 8px 12px;
`};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    padding: 8px 10px;
  `};

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
  }
`

export default function Header() {
  const exploreFlag = useExploreFlag()

  const { account, chainId } = useWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const [darkMode] = useDarkModeManager()
  const { deprecated_white, deprecated_black } = useTheme()

  // FF: Remove claim stuff
  // const toggleClaimModal = useToggleSelfClaimModal()
  // const availableClaim: boolean = useUserHasAvailableClaim(account)
  // const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)
  // const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()

  const { pathname } = useLocation()

  const {
    infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = getChainInfoOrDefault(chainId)

  // work around https://github.com/remix-run/react-router/issues/8161
  // as we can't pass function `({isActive}) => ''` to className with styled-components
  const isPoolActive =
    pathname.startsWith('/pool') ||
    pathname.startsWith('/add') ||
    pathname.startsWith('/remove') ||
    pathname.startsWith('/increase') ||
    pathname.startsWith('/find')

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      {/* FF: Remove claim modal */}
      {/* <ClaimModal /> */}
      <Title href=".">
        <UniIcon>
          <Logo fill={darkMode ? deprecated_white : deprecated_black} height="100%" title="logo" />
          <HolidayOrnament />
        </UniIcon>
      </Title>
      <HeaderLinks>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>Swap</Trans>
        </StyledNavLink>
        {exploreFlag === ExploreVariant.Enabled && (
          <StyledNavLink id={`explore-nav-link`} to={'/explore'}>
            <Trans>Explore</Trans>
          </StyledNavLink>
        )}
        <StyledNavLink
          data-cy="pool-nav-link"
          id={`pool-nav-link`}
          to={'/pool'}
          className={isPoolActive ? activeClassName : undefined}
        >
          <Trans>Pools</Trans>
        </StyledNavLink>
        {/* <StyledExternalLink id={`docs-nav-link`} href={'https://docs.fireflydex.xyz/'}>
          <Trans>Docs</Trans>
        </StyledExternalLink> */}
        {/* FF: Replace bridge link with airdrop link */}
        {/* <StyledExternalLink id={`bridge-nav-link`} href={'https://pacific-bridge.manta.network/'}>
          <Trans>Bridge</Trans>
        </StyledExternalLink> */}
        <StyledExternalLink id={`airdrop-nav-link`} href={'https://new-illumination.com'}>
          <Trans>Airdrop</Trans>
        </StyledExternalLink>
        {/* FF: Remove vote page */}
        {/* {(!chainId || chainId === SupportedChainId.MAINNET) && (
          <StyledNavLink id={`vote-nav-link`} to={'/vote'}>
            <Trans>Vote</Trans>
          </StyledNavLink>
        )} */}
        <StyledNavLink data-cy="about-us" id={`about-us-nav-link`} to={'/about-us'}>
          <Trans>About</Trans>
        </StyledNavLink>
        <StyledExternalLink id={`charts-nav-link`} href={infoLink}>
          <Trans>Info</Trans>
        </StyledExternalLink>
      </HeaderLinks>
      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          {/* FF: Remove claim modal */}
          <AccountElement active={!!account}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.75rem" pr=".4rem" fontWeight={500}>
                <Trans>
                  {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol}
                </Trans>
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElement>
          <Menu />
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
